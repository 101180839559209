import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyDeN7dn5JcHQCeOp0eh8YxyOQ10pcN_zks',
  authDomain: 'groundwork-made.firebaseapp.com',
  projectId: 'groundwork-made',
  storageBucket: 'groundwork-made.appspot.com',
  messagingSenderId: '485672648845',
  appId: '1:485672648845:web:d349cf32390099e880e2c4',
  databaseURL: 'https://groundwork-made-default-rtdb.firebaseio.com/',
  measurementId: 'G-RW9ZQYYPBD',
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database();

export default database;

import database from './firebase';

import {ArrowUpIcon, ArrowDownIcon} from '@primer/octicons-react';
import {useState, useEffect} from 'react';
import {useEffectOnce} from 'react-use';

const levelRef = database.ref('level');

const Controls = () => {
  const [level, setLevel] = useState(0);

  useEffectOnce(() => {
    levelRef.on('value', (snapshot) => {
      const data = snapshot.val();
      setLevel(data);
    });
  });

  useEffect(() => {
    levelRef.set(level);
  }, [level]);

  return (
    <div className="Controls">
      <div
        className="down"
        onClick={() => {
          if (level < 0) {
            setLevel((level) => level + 1);
          }
        }}
      >
        <ArrowUpIcon />
      </div>
      <div className="number">{level}</div>
      <div
        className="up"
        onClick={() => {
          if (level > -7) {
            setLevel((level) => level - 1);
          }
        }}
      >
        <ArrowDownIcon />
      </div>
    </div>
  );
};

export default Controls;

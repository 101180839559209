import database from './firebase';

import {CheckCircleFillIcon} from '@primer/octicons-react';
import {useState, useEffect, useRef} from 'react';
import {CircularProgressbar} from 'react-circular-progressbar';
import {useHistory} from 'react-router-dom';
import {useSpring, animated, config} from 'react-spring';
import {useEffectOnce, useTimeoutFn, useInterval} from 'react-use';
import 'react-circular-progressbar/dist/styles.css';

const weightRef = database.ref('weight');

const Weight = () => {
  const history = useHistory();
  const [weight, setWeight] = useState(0);
  const [weightStyle, api] = useSpring(() => ({
    opacity: 0,
    transform: `scale(1)`,
  }));

  const numberSpring = useSpring({val: weight, from: {val: 0}});
  const [successSpring, successSpringApi] = useSpring(() => ({
    transform: 'translateY(600px)',
    config: config.wobbly,
  }));

  const [percentage, setPercentage] = useState(0);
  const [success, setSuccess] = useState(false);
  const submitSoundRef = useRef();
  const [isReady, cancel, reset] = useTimeoutFn(() => {
    submitSoundRef.current.play();
    successSpringApi({transform: 'translateY(0)'});
    setSuccess(true);
  }, 5000);

  useEffectOnce(() => {
    weightRef.on('value', (snapshot) => {
      const data = snapshot.val();
      setWeight(data);
      setPercentage(0);
      api.start({opacity: 1, transform: `scale(${data / 5})`});
    });

    if (isReady() === false) {
      cancel();
    }
  });

  useEffect(() => {
    if (weight > 5) {
      reset();
      setPercentage(100);
    }
  }, [weight, reset]);

  useInterval(() => {
    if (weight === 0) {
      history.push('/');
    }
  }, 5000);

  useEffect(() => {
    if (success) {
      api.start({opacity: 1, transform: `scale(150)`});

      setTimeout(() => {
        history.push(`/impact/${weight}`);
      }, 2000);
    }
  }, [success, api, history, weight]);

  return (
    <div className="Weight">
      <animated.div style={weightStyle} className="circle" />
      {!success && (
        <>
          <animated.div className="value">
            {weight >= 0
              ? numberSpring.val.interpolate((val) => Math.floor(val))
              : 0}
          </animated.div>
          <div className="unit">g</div>
        </>
      )}

      <animated.div className="progress" style={weightStyle}>
        <CircularProgressbar
          value={percentage}
          styles={{
            root: {width: 18, height: 18},
            path: {
              transition: 'stroke-dashoffset 4.4s ease-in-out 1s',
            },
          }}
        />
      </animated.div>

      <animated.div className="success" style={successSpring}>
        <CheckCircleFillIcon size={200} />
      </animated.div>

      <audio src="/assets/sounds/enter_weight.wav" autoPlay />
      <audio src="/assets/sounds/submit_weight.wav" ref={submitSoundRef} />
    </div>
  );
};

export default Weight;

import Controls from './Controls';
import Home from './Home';
import Impact from './Impact';
import InteractiveExperience from './InteractiveExperience';
import Weight from './Weight';

import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/earth">
          <InteractiveExperience />
        </Route>

        <Route path="/weight">
          <Weight />
        </Route>

        <Route path="/impact/:weight">
          <Impact />
        </Route>

        <Route path="/controls">
          <Controls />
        </Route>

        <Route path="/bump/:weight">
          <Home />
        </Route>

        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

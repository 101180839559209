import database from './firebase';

import cx from 'classnames';
import {useState, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTrail, useSpring, animated, config} from 'react-spring';
import {useTimeoutFn, useEffectOnce} from 'react-use';

const helloCharacters = ['H', 'e', 'l', 'l', 'o', '!'];
// const communityStats = [
//   {
//     name: 'Ring Street',
//     households: 2345,
//     co2: 9204,
//   },
//   {
//     name: 'Fox Point',
//     households: 1241,
//     co2: 9312,
//   },
//   {
//     name: 'College Hill',
//     households: 574,
//     co2: 3412,
//   },
// ];

const weightRef = database.ref('weight');
const logRef = database.ref('log');

function Home() {
  const history = useHistory();

  const [hello, helloApi] = useTrail(6, () => ({
    transform: 'translateY(-600px)',
    display: 'block',
    config: config.wobbly,
  }));

  const [background, backgroundApi] = useSpring(() => ({
    transform: 'translateY(600px)',
    config: config.molasses,
  }));

  const [communityReport, communityReportApi] = useTrail(12, () => ({
    transform: 'translateY(450px)',
    display: 'none',
    config: config.wobbly,
  }));

  const [isCommunityReport, setIsCommunityReport] = useState(false);
  const [logs, setLogs] = useState([]);

  useEffectOnce(() => {
    backgroundApi.start({transform: 'translateY(0px)'});
    helloApi.start({transform: 'translateY(0px)'});

    weightRef.on('value', (snapshot) => {
      const data = snapshot.val();
      if (data > 5) {
        history.push('/weight');
      }
    });

    logRef
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          setLogs(snapshot.val());
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });

  const totalco2 = useMemo(() => {
    let co2draft = 0;

    Object.keys(logs).map((entryKey) => {
      co2draft = co2draft + +logs[entryKey].weight;
    });

    return co2draft;
  }, [logs]);

  const logLength = Object.keys(logs).length;

  useTimeoutFn(() => {
    helloApi.start({transform: 'translateY(-600px)'});
    backgroundApi.start({transform: 'translateY(-1100px)'});
  }, 2000);

  useTimeoutFn(() => {
    helloApi.start({display: 'none'});
  }, 2400);

  useTimeoutFn(() => {
    communityReportApi.start({display: 'block', transform: 'translateY(0px)'});
    setIsCommunityReport(true);
  }, 2500);

  return (
    <div className="Home">
      <div className="interactive"></div>

      {hello.map((style, index) => (
        <animated.div key={index} className="hello" {...{style}}>
          {helloCharacters[index]}
        </animated.div>
      ))}

      <animated.div className="background" style={background}></animated.div>

      <div className={cx('community-report', {active: isCommunityReport})}>
        <div className="meta">
          <animated.div className="name" style={communityReport[0]}>
            Ring Street Community Garden
          </animated.div>
          <a href="/earth" className="weather">
            <animated.div className="icon" style={communityReport[1]} />
          </a>
        </div>

        <div className="stats">
          <div className="today">
            <animated.label style={communityReport[2]}>TODAY</animated.label>

            <animated.div className="household" style={communityReport[3]}>
              <div className="number">{logLength}</div>
              <div className="description">
                households contributed food scraps today
              </div>
            </animated.div>
            <animated.div className="co2" style={communityReport[4]}>
              <div className="number">{totalco2}</div>
              <div className="description">
                of carbon dioxide have been prevented
              </div>
            </animated.div>
          </div>

          <div className="all-time">
            <animated.label style={communityReport[6]}>ALL TIME</animated.label>

            <animated.div className="household" style={communityReport[7]}>
              <div className="number">{2345 + logLength}</div>
              <div className="description">
                households contributed food scraps today
              </div>
            </animated.div>
            <animated.div className="co2" style={communityReport[8]}>
              <div className="number">{`${totalco2 + 9204}lbs`}</div>
              <div className="description">
                of carbon dioxide have been prevented
              </div>
            </animated.div>
          </div>
        </div>
      </div>

      <audio src="/assets/sounds/ambient.wav" autoPlay />
    </div>
  );
}

export default Home;

import database from './firebase';

import {formatISO} from 'date-fns';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {useTrail, animated, config} from 'react-spring';
import {useEffectOnce, useTimeoutFn} from 'react-use';

const logRef = database.ref('log');
const latestLogRef = database.ref('latestLog');
const weightRef = database.ref('weight');

const Impact = () => {
  const history = useHistory();
  const {weight} = useParams();
  useTimeoutFn(() => {
    history.push(`/bump`);
  }, 7000);

  const getStats = () => {
    const pounds = weight * 52 * 0.00220462;
    const co2 = pounds * 0.92391;
    const co2ton = co2 / 2000;
    let car = pounds / 2500;
    let recycled = co2ton * 0.316;
    const gas = co2ton * 102;
    let home = co2ton * 0.098;
    const light = co2 * 0.015;
    const wind = co2ton * 0.0002;
    let car2 = pounds * 0.0003;
    const car3 = pounds * 1.1;
    const fridge = pounds * 0.003;
    const elephant = pounds * 0.00008;

    if (car < 0.01) car = 0.01;
    if (car2 < 0.01) car2 = 0.01;
    if (recycled < 0.01) recycled = 0.01;
    if (home < 0.01) home = 0.01;

    return {
      carbon_dioxide: Math.floor(co2),
      carbon_dioxide_in_tons: co2ton,
      car: car,
      recycled: recycled,
      gas: gas.toFixed(2),
      home: home,
      incandescent_light_to_led: light,
      wind: wind,
      miles_driven_by_average_passenger_vehicle: Math.floor(car3),
      equivalent_weight_of_car: car2,
      fridge: fridge.toFixed(3),
      equivalent_weight_of_elephant: elephant,
    };
  };

  const [statsSpring, statsSpringApi] = useTrail(12, () => ({
    transform: 'translateY(1000px)',
    config: config.wobbly,
  }));

  useEffectOnce(() => {
    const newLogRef = logRef.push();
    newLogRef.set({timestamp: formatISO(new Date()), weight: weight});
    latestLogRef.set({timestamp: formatISO(new Date()), weight: weight});
    weightRef.set(0);

    setTimeout(() => {
      statsSpringApi.start({transform: 'translateY(0px)'});
    }, 400);
  });

  return (
    <div className="Impact">
      <animated.div className="co2" style={statsSpring[0]}>{`${
        getStats()['carbon_dioxide']
      }lbs of carbon dioxide saved per year`}</animated.div>

      <animated.div
        className="car"
        style={statsSpring[1]}
      >{`Equivalent to greenhouse gas emissions from ${
        getStats()['miles_driven_by_average_passenger_vehicle']
      } miles driven by an average passenger vehicle`}</animated.div>

      <animated.div
        className="gas"
        style={statsSpring[2]}
      >{`Equivalent to carbon dioxide emissions from ${
        getStats()['gas']
      } gallons of gasoline consumed per year`}</animated.div>

      <animated.div
        className="fridge"
        style={statsSpring[3]}
      >{`Equivalent to weight of ${
        getStats()['fridge']
      } fridges per year`}</animated.div>

      <audio src="/assets/sounds/impact.wav" autoPlay />
    </div>
  );
};

export default Impact;

import database from './firebase';

import {ArrowUpIcon, ArrowDownIcon} from '@primer/octicons-react';
import * as d3 from 'd3';
// import produce from 'immer';
import {useState, useEffect} from 'react';
import {useSpring, animated} from 'react-spring';
import {useEffectOnce} from 'react-use';
// import {useInterval} from 'react-use';

// const FRAMES_META = {
//   land: 37,
//   northern_lights: 26,
// };

const levelRef = database.ref('level');

const InteractiveExperience = () => {
  const [level, setLevel] = useState(0);

  // useInterval(() => {
  //   setPuppeteer(
  //     produce((draft) => {
  //       draft.landFrame =
  //         puppeteer.landFrame === 37 ? 1 : puppeteer.landFrame + 1;
  //     })
  //   );
  // }, 100);

  // useInterval(() => {
  //   setPuppeteer(
  //     produce((draft) => {
  //       draft.artifactFrame =
  //         puppeteer.artifactFrame === FRAMES_META[puppeteer.artifact]
  //           ? 1
  //           : puppeteer.artifactFrame + 1;
  //     })
  //   );
  // }, 100);

  const [earthStyle, api] = useSpring(() => ({
    transform: 'translateY(300px)',
    config: {mass: 1, tension: 200, friction: 30},
  }));

  const [atmosphereStyle, atmosphereApi] = useSpring(() => ({
    fill: 'transparent',
    config: {mass: 1, tension: 200, friction: 30},
  }));

  useEffect(() => {
    api.start({transform: 'translateY(0px)'});
  }, [api]);

  useEffect(() => {
    atmosphereApi.start({
      fill:
        level < 0
          ? d3.interpolateOranges(-(level / 20) + 0.4)
          : d3.interpolateBlues(level / 20 + 0.4),
    });
  }, [atmosphereApi, level]);

  useEffectOnce(() => {
    levelRef.on('value', (snapshot) => {
      const data = snapshot.val();
      setLevel(data);
    });
  });

  return (
    <div className="Earth">
      <div className="controls">
        <div
          className="arrow-up"
          onClick={() => {
            if (level < 0) {
              setLevel((level) => level + 1);
            }
          }}
        >
          <ArrowUpIcon />
        </div>

        <div className="level">{level}</div>

        <div
          className="arrow-down"
          onClick={() => {
            if (level > -7) {
              setLevel((level) => level - 1);
            }
          }}
        >
          <ArrowDownIcon />
        </div>
      </div>

      <animated.svg
        viewBox="0 0 1920 1080"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background"
        style={earthStyle}
      >
        <g clipPath="url(#clip0)">
          <animated.path
            d="M1964 1244C1964 1112.15 1938.03 981.597 1887.58 859.786C1837.12 737.975 1763.17 627.295 1669.94 534.065C1576.71 440.835 1466.03 366.881 1344.21 316.425C1222.4 265.969 1091.85 240 960 240C828.153 240 697.597 265.969 575.786 316.425C453.975 366.881 343.295 440.835 250.065 534.065C156.835 627.295 82.8806 737.975 32.4249 859.786C-18.0308 981.597 -44 1112.15 -44 1244L1964 1244Z"
            fill="#60A5FA"
            fillOpacity="0.5"
            style={atmosphereStyle}
          />
          <path
            d="M1829 1244C1829 1013.53 1737.44 792.493 1574.48 629.524C1411.51 466.555 1190.47 375 960 375C729.527 375 508.493 466.555 345.524 629.524C182.555 792.493 91 1013.53 91 1244L1829 1244Z"
            fill="#1E40AF"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="1920" height="1080" fill="white" />
          </clipPath>
        </defs>
      </animated.svg>

      {/* <img className="land" src={`/assets/land/${puppeteer.landFrame}.png`} />

      <img
        className="artifact"
        src={`/assets/${puppeteer.artifact}/${puppeteer.artifactFrame}.png`}
      />*/}

      <img className="land" src={`/assets/flood/${level}.png`} />
    </div>
  );
};

export default InteractiveExperience;
